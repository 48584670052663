import React from "react"
import { FooterNewsletter } from "../PageFooter/FooterNewsletter"

const NewsletterSignup = () => (
  <div
    css={theme => ({
      display: "none",
      [theme.mediaQueries.desktop]: {
        display: "initial",
        gridColumn: "2/3",
        alignSelf: "end",
      },
    })}
  >
    <h4 css={{ textTransform: `uppercase` }}>
      Get Gatsby updates to your inbox
    </h4>
    <h5
      css={theme => ({
        fontWeight: theme.fontWeights.body,
        fontFamily: theme.fonts.body,
      })}
    >
      Be the first to learn about new Gatsby features, best practices, and
      community events.
    </h5>
    <div css={{ "& > div, & > div > form": { width: "100%" } }}>
      <FooterNewsletter hideLabel />
    </div>
  </div>
)

export default NewsletterSignup
